/* eslint-disable eqeqeq,curly */
import {PLATFORM} from 'aurelia-pal';
import moment from 'moment';
//import 'jquery';
/*import * as $ from 'jquery';
window.$ = $;
window.jQuery = $;

import 'bootstrap';*/
import { backend, init, user } from './services/backend';
import {inject} from 'aurelia-framework';
import {Redirect, RedirectToRoute} from 'aurelia-router';

//import WOW from './js/WOW.js';
//window.WOW = WOW;

import {I18N} from 'aurelia-i18n';
import {currencies} from './currency';

@inject(backend, user, I18N)
export class App {
    alerts = [];
    headerClass = '';
    footerClass = '';
    scrollTop = 0;
    dark = true;
    menuCollapsed = false;
    currency = 'EUR';
    currencies = currencies;

    configureRouter(config, router) {
    	let app = this;
    	const preActivate = {
    		run: (navigationInstruction, next) => {
    			console.log('navigationInstruction:', navigationInstruction);
    			this.headerClass = '';
    			this.footerClass = '';
    			this.dark = true;
    			this.apartmentAddr = '';

    			this.model = navigationInstruction.viewPortInstructions.default.component.viewModel;
    			if (this.model.check) {
    				return this.model.check(navigationInstruction, next);
    			}
    			return next();
    		}
    	};

		const postRender = {
			run: () => {
				gtag('js', new Date());
				gtag('config', 'AW-11030423389');
			}
		};

    	const authorize = {
    		run(navigationInstruction, next) {
    			app.authPage = false;

    			if (navigationInstruction.getAllInstructions().some(i => i.config.settings.auth)) {
    				app.authPage = true;
    				return new Promise((resolve, reject) => {
    					console.log('Promising...');
    					init((data) => {
    						console.log('User data:', data);
    						console.log('Userrrr:', user, this.user);
    						if (user.anonymous) {
    							console.log(navigationInstruction);
    							return resolve(next.cancel(new RedirectToRoute('login', {r: navigationInstruction.fragment})));
    						}
    						resolve(next());
    					});
    				});
    			}

    			return new Promise((resolve, reject) => {
    				init((data) => {
    					console.log('User data:', data);
    					resolve(next());
    				});
    			});
    		}
    	};

    	const homeStratagy = (instruction) => {
    		console.log('homeStratagy:', instruction);
    		if (instruction.queryParams.locationKey) {
    			instruction.config.moduleId = PLATFORM.moduleName('after-search', 'editor');
    			this.headerClass = 'header_mod';
    			//instruction.config.href = instruction.fragment;
    			//instruction.config.layoutView = PLATFORM.moduleName('layoutold.html');
    		} else {
    			instruction.config.moduleId = PLATFORM.moduleName('landing', 'landing');
    			instruction.config.layoutView = PLATFORM.moduleName('layout.html');
    		}
    		if (instruction.config.viewPorts) {
    			instruction.config.viewPorts.default.moduleId = instruction.config.moduleId;
    			if (instruction.config.layoutView) {instruction.config.viewPorts.default.layoutView = instruction.config.layoutView;}
    		}
    		//this.router.refreshNavigation();
    		//instruction.config.href = instruction.fragment
    	};
    	const confLayout = (instruction) => {
    		console.log('Navigate:', instruction);
    		if (!instruction.config.viewPorts) {instruction.config.viewPorts = {default: {}};}

    		instruction.config.viewPorts.default.layoutViewModel = this;
    		instruction.config.viewPorts.default.moduleId = instruction.config.moduleId;

    		/*if (instruction.viewPortInstructions.default && instruction.viewPortInstructions.default.layoutInstruction)
                instruction.viewPortInstructions.default.layoutInstruction.viewModel = this;*/
    	};

    	config.title = 'Renting housing for business and travel – PanAp\n' +
			'The best prices for housing on holidays, business trips and adventures. We\'ll find you the best option tailored to what you’re looking for.\n';
    	config.options.pushState = true;
    	config.options.hashChange = false;
    	config.addAuthorizeStep(authorize);
    	config.addPreActivateStep(preActivate);

    	config.map([
    		{route: '', name: 'home', layoutViewModel: this, navigationStrategy: homeStratagy /*moduleId: PLATFORM.moduleName('landing')/*navigationStrategy: homeStratagy, title: 'ApUp'*/},
			{route: 'login', name: 'login', moduleId: PLATFORM.moduleName('login'), layoutView: PLATFORM.moduleName('layout.html'), navigationStrategy: confLayout},
    		{route: 'login', name: 'login', moduleId: PLATFORM.moduleName('login'), layoutView: PLATFORM.moduleName('layout.html'), navigationStrategy: confLayout},
    		{route: 'close', name: 'close', moduleId: PLATFORM.moduleName('close'), layoutView: PLATFORM.moduleName('layout.html'), navigationStrategy: confLayout},
    		{route: 'profile', name: 'profile', moduleId: PLATFORM.moduleName('profile'), navigationStrategy: confLayout,  settings: {auth: true}},
    		{route: 'payout', name: 'payout', moduleId: PLATFORM.moduleName('payout'), navigationStrategy: confLayout,  settings: {auth: true}},
    		{route: 'requests', moduleId: PLATFORM.moduleName('requests'), navigationStrategy: confLayout,  settings: {auth: true}},
    		{route: 'request/:id', moduleId: PLATFORM.moduleName('request'), layoutView: PLATFORM.moduleName('layout.html'), navigationStrategy: confLayout,  settings: {auth: true}},
    		{route: 'request/:requestId/:offerId', moduleId: PLATFORM.moduleName('apartment-preview'), layoutView: PLATFORM.moduleName('layout.html'), navigationStrategy: confLayout,  settings: {auth: true}},
		    {route: 'book/:requestId/:offerId', name: 'book', moduleId: PLATFORM.moduleName('book'), navigationStrategy: confLayout,  settings: {auth: true}},
            {route: 'payment/:requestId/:offerId', name: 'payment', moduleId: PLATFORM.moduleName('payment'), navigationStrategy: confLayout,  settings: {auth: true}},
    		{route: 'apartment/add', moduleId: PLATFORM.moduleName('apartment-details', 'editor'), navigationStrategy: confLayout,  settings: {auth: true}},
    		{route: 'apartment/', moduleId: PLATFORM.moduleName('apartment-details', 'editor'), navigationStrategy: confLayout},
    		// {route: 'apartment/:id/', moduleId: PLATFORM.moduleName('apartment-details', 'editor'), navigationStrategy: confLayout,  settings: {auth: true}},
            {route: 'apartment/:id/', name: 'apartment-details', moduleId: PLATFORM.moduleName('apartment-details', 'editor'), navigationStrategy: confLayout,  settings: {auth: true}},
    		{route: 'apartment/:apartmentId/preview', moduleId: PLATFORM.moduleName('apartment-preview'), navigationStrategy: confLayout,  settings: {auth: true}},
    		{route: 'calendar/:id', moduleId: PLATFORM.moduleName('apartment-calendar', 'editor'), navigationStrategy: confLayout,  settings: {auth: true}},
    		//{route: 'apartments', moduleId: PLATFORM.moduleName('apartments'), navigationStrategy: confLayout,  settings: {auth: true}},
    		{route: 'terms', moduleId: PLATFORM.moduleName('terms'), navigationStrategy: confLayout,  settings: {}},
    		{route: 'help', moduleId: PLATFORM.moduleName('faq'), navigationStrategy: confLayout,  settings: {}},
		    {route: 'hosts', moduleId: PLATFORM.moduleName('faq-host'), navigationStrategy: confLayout,  settings: {}},
		    {route: 'contacts', moduleId: PLATFORM.moduleName('contacts'), navigationStrategy: confLayout,  settings: {}}
    	]);

    	config.mapUnknownRoutes({ moduleId: PLATFORM.moduleName('not-found'),  layoutViewModel: this, navigationStrategy: confLayout, settings: {} });
    	this.router = router;

    	backend.on('logout', () => {
    		if (this.authPage) {
    			//this.router.navigate(this.router.currentInstruction ? this.router.currentInstruction.fragment : '/');
    			this.router.navigateToRoute('login', {r: this.router.currentInstruction ? this.router.currentInstruction.fragment : '/'});
    		}
    	});
    }

    determineActivationStrategy(params, routeConfig, navigationInstruction) {
    	console.log('determineActivationStrategy:', {params, routeConfig, navigationInstruction});
    	return 'invoke-lifecycle';
    }/**/

    constructor(backend, user, i18n) {
    	this.backend = backend;
    	this.user = user;
    	this.i18n = i18n;
  	    this.window = window;
  	    this.locale = i18n.getLocale();

      	let locale = localStorage.getItem('lang');
      	if (locale) {
    		this.setLocale(locale);
    	}

    	backend.on('user', u => {
    		$('#loginModal').modal('hide');
    		$('#registerModal').modal('hide');
    	});
    }

    setLocale(locale) {
    	moment.locale(locale);

    	this.i18n.setLocale(locale).then(() => {
    		this.locale = locale;
    		if (localStorage) {
    			localStorage.setItem('lang', locale);
    		}
    	}).catch(error => console.error(error.message, error));
    }

    login() {
    	return new Promise((resolve, reject) => {
    		user.editor.error = '';
    		user.login({
    			strategy: 'local',
    			email: user.editor.email,
    			password: user.editor.password
    		}).then((user) => {
    			resolve(user);
    		}).catch((e) => {
    			user.editor.error = e.message;
    			reject(e);
    		});
    	});
    }

    register() {
    	return new Promise((resolve, reject) => {
		  if (user.editor.password != user.editor.confirm) {
			  user.editor.error = 'Password does not match';
			  return;
		  }
		  user.editor.error = '';
		  backend.service('users').create({
			  email: user.editor.email,
			  password: user.editor.password
		  }).then((data) => {
			  console.log('New user created!', data);
			  gtag('event', 'conversion', {'send_to': 'AW-11030423389/6HQdCM7_jYMYEN3O24sp'});
			  this.login().then(()=>{
			  	resolve(user);
			  }).catch((e)=>{
			  	reject(e);
			  });
		  }).catch((e) => {
			  user.editor.error = e.message;
			  reject(e);
		  });
	  });
    }
    logout() {
    	user.logout();
    	//this.router.navigate('/');
    }

    resendConfirmationEmail() {
    	user.resendConfirmationEmail().then(data => {
    	    this.alert(`Verification email was sent to ${data.email}`, 'success', true, 4000);
        });
    }

    attached() {
    	console.log('App attached!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    	if (window.location.hash && window.location.hash != '#') {
    		let id = window.location.hash.substr(1);
    		console.log(id);
    		const el = document.getElementById(id);
    		if (el) {
    			el.scrollIntoView(true);
    			el.focus();
    		}
    	}
    	$(window).on('scroll resize', (e) => {
    		function GetZoomFactor() {
    			let factor = 1;
    			if (document.body.getBoundingClientRect) {
    				// rect is only in physical pixel size in IE before version 8
    				let rect = document.body.getBoundingClientRect();
    				let physicalW = rect.right - rect.left;
    				let logicalW = document.body.offsetWidth;

    				// the zoom level is always an integer percent value
    				factor = Math.round((physicalW / logicalW) * 100) / 100;
    			}
    			return factor;
    		}

    		this.scrollTop = window.scrollY || (document.documentElement.scrollLeft / GetZoomFactor());
    		//this.windowWidth = window.innerWidth;
    		/*let c;
            if (this.dark)
                c = 'bg-pan-dark'
            else
                c = 'bg-pan-light';
            if (this.scrollTop > 10)
                $(this.navbar).slideUp(400).addClass(c);
            else
                $(this.navbar).slideUp(400).removeClass(c);
                */
    	});

    	//main($);

    	//$.fn.selectpicker.Constructor.BootstrapVersion = '4';
    }

    jump(href) {
    	let [route, id] = href.split('#');
    	if (this.router.currentInstruction.fragment != (route || '/')) {
    		setTimeout(()=>{this.jump(href);}, 100);
    		this.router.navigate(route || '/');
    		return;
    	}
    	console.log('Before jump:', id);
    	window.location.hash = id;
    	console.log('Jumping:', id);
    	const el = document.getElementById(id);
    	if (el) {
    		el.scrollIntoView(true);
    		el.focus();
    	}
    }

    oauth(provider) {
    	this.oauthWin = window.open('/oauth/' + provider + '?redirect=close', 'oauth',  'menubar=no,location=no');
    	let interval = setInterval(()=>{
    		if (this.oauthWin.closed) {
    			clearInterval(interval);
    			init((data)=>{
    				console.log('OAuth closed:', data);
    			});
    		}
    	}, 100);
    }

    alert(text, type, close, timeout) {
    	if (close === undefined)
    		close = true;
    	type = type || 'danger';
    	let alert = {text, type, close};
    	this.alerts.push(alert);
    	if (timeout) {
    		setTimeout(() => {
    			let i = this.alerts.indexOf(alert);
    			if (i >= 0)
    				this.alerts.splice(i, 1);
    		}, timeout);
    	}
    }

    menuCollapse() {
    	this.menuCollapsed = !this.menuCollapsed;
    	return true;
    }
}
